
import React, { useEffect, useState } from 'react';
import { Empty, Input } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { SearchButton } from '../../components/buttons/buttons';
import { ReactComponent as SearchIcon } from '../../icons/search-blue.svg';
import { Cascader } from 'antd';

import './knowledgeHub.scss';

const KnowledgeHub = () => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();
    const posts = [
        {
            id: 2, title: 'IDEX Biometrics and TaluCard bring inclusive biometric payment solutions to market in Europe', description: `TaluCard, in collaboration with IDEX Biometrics and a London issuer, is launching 
a cutting-edge biometric payment solution focused on enhancing accessibility 
and security for all users, including those with visual impairments and the elderly.`, categoryId: 1, topicId: 3, isVideo: true, videoUrl: 'fingerprint.mp4'
        },
        {id: 3, title: 'Championing Accessibility: The Future of Inclusive Technology', description: `Join us in our mission to create an inclusive digital world. Learn more 
about our accessibility features today and see how we're making 
technology work for everyone.`,  categoryId: 4, topicId: 3, isVideo: false, imageUrl: 'images/blog/post3.jpg', imageUrlSmall: 'images/blog/post3-small.jpg'},
{
    id: 1, title: 'Introducing our Innovative Inclusive Payment Solution: TaluTouch', description: `TaluCard, supported by Blukite Finance Limited, focuses on inclusive and 
    accessible payment solutions. Launch slated for Q3 2024, with features 
    designed for visually impaired and sighted users.`, categoryId: 3, topicId: 1, isVideo: true, videoUrl: 'TaluDemo.mp4'
},
{id: 4, title: 'Why ESG Matters: Building Resilient and Responsible Businesses', description: `In today’s rapidly changing business world, ESG is no longer just about
ethics; it’s a key driver of long-term success. Are you integrating ESG 
into your business strategy?`,  categoryId: 4, topicId: 1, isVideo: true, videoUrl: 'esg.mp4'},

{id: 5, title: 'How BNPL is Revolutionising Credit', description: `Buy Now, Pay Later (BNPL) is transforming how consumers access 
and manage credit, offering a flexible and transparent alternative to 
traditional credit, especially in e-commerce.`,  categoryId: 4, topicId: 3, isVideo: false, imageUrl: 'images/blog/post5.jpg', imageUrlSmall: 'images/blog/post5-small.jpg'},
    ];

    const categories = [
        {
            id: 1, name: "News", topics: [
                { id: 1, name: "Trending Topics" },
                { id: 2, name: "Latest News" },
                { id: 3, name: "Press Releases" },
            ]
        },
        {
            id: 2, name: "Tutorials & Explainers", topics: [
                { id: 1, name: "Guides & Tutorials" },
                { id: 2, name: "Podcasts" },
                { id: 3, name: "Explainer" },
            ]
        },
        {
            id: 3, name: "Publications", topics: [
                { id: 1, name: "Case Studies" },
                { id: 2, name: "White Papers " },
            ]
        },
        {
            id: 4, name: "Industry Insights", topics: [
                { id: 1, name: "Research" },
                { id: 2, name: "R&D" },
                { id: 3, name: "Intelligence" },
                { id: 4, name: "Q&A" },
            ]
        },
    ];

    const categoriesOptions = [
        {
            value: 'news',
            label: 'News',
            children: [
                {
                    value: 'trending-topics',
                    label: 'Trending Topics',
                },
                {
                    value: 'latest-news',
                    label: 'Latest News',
                },
                {
                    value: 'press-releases',
                    label: 'Press Releases',
                },
            ],
        },
        {
            value: 'tutorials-explainers',
            label: 'Tutorials & Explainers',
            children: [
                {
                    value: 'guides-tutorials',
                    label: 'Guides & Tutorials',
                },
                {
                    value: 'podcasts',
                    label: 'Podcasts',
                },
                {
                    value: 'explainer',
                    label: 'Explainer',
                },
            ],
        },
        {
            value: 'publications',
            label: 'Publications',
            children: [
                {
                    value: 'case-studies',
                    label: 'Case Studies',
                },
                {
                    value: 'white-papers',
                    label: 'White Papers',
                },
            ],
        },
        {
            value: 'industry-insights',
            label: 'Industry Insights',
            children: [
                {
                    value: 'research',
                    label: 'Research',
                },
                {
                    value: 'rnd',
                    label: 'R&D',
                },
                {
                    value: 'intelligence',
                    label: 'Intelligence',
                },
                {
                    value: 'qa',
                    label: 'Q&A',
                },
            ],
        },
    ];

    const onChange = (value) => {
        if (!value || value.length === 0) {
            setActiveCategoryId(null);
            setActiveTopicName(null);
        } else {
            const [categoryValue, topicValue] = value;
            const category = categoriesOptions.find(category => category.value === categoryValue);
            const topic = category?.children.find(topic => topic.value === topicValue);
            setActiveCategoryId(categories.find(cat => cat.name.toLowerCase() === category.label.toLowerCase()).id);
            setActiveTopicName(topic?.label);
        }
    };

    const [activeCategoryId, setActiveCategoryId] = useState(null);
    const [activeTopicName, setActiveTopicName] = useState(null);

    useEffect(() => {
        setActiveTopicName(null);
    }, [activeCategoryId]);

    useEffect(() => {
        if (!searchValue && !activeCategoryId && !activeTopicName) {
            setSearchResults(posts);
        } else {
            handleSearch();
        }
    }, [searchValue, activeCategoryId, activeTopicName]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        setIsSearching(true);

        const lowerCaseSearchValue = searchValue.toLowerCase();
        const filteredPosts = posts.filter(post => {
            const category = categories.find(c => c.id === post.categoryId);
            const topic = category?.topics.find(t => t.id === post.topicId);

            const matchesSearchValue = post.title.toLowerCase().includes(lowerCaseSearchValue) ||
                post.description.toLowerCase().includes(lowerCaseSearchValue) ||
                category?.name.toLowerCase().includes(lowerCaseSearchValue) ||
                topic?.name.toLowerCase().includes(lowerCaseSearchValue);

            const matchesCategory = activeCategoryId ? post.categoryId === activeCategoryId : true;
            const matchesTopic = activeTopicName ? topic?.name === activeTopicName : true;

            return matchesSearchValue && matchesCategory && matchesTopic;
        });

        setSearchResults(filteredPosts);
        setIsSearching(false);
    };

    return (
        <div className="flex flex-col md:flex-row container gap-16 mt-0 sm:mt-24 lg:mt-24 infoPage">
            <div className="hidden sm:block sidebar knowledgeHub__filter">
                <div className='mt-3'>
                    <p className='text-lg'>Categories</p>
                    <div className='mt-5'>
                        {categories.map(x => {
                            return (
                                <div key={x.id} onClick={() => setActiveCategoryId(x.id)} className={activeCategoryId === x.id ? "categories__item categories__item--active" : "categories__item"}>
                                    {x.name}
                                    {activeCategoryId === x.id &&
                                        <span onClick={(event) => {
                                            event.stopPropagation();
                                            setActiveCategoryId(null);
                                        }}>
                                            <svg id="fi_2961937" height="14" viewBox="0 0 64 64" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m4.59 59.41a2 2 0 0 0 2.83 0l24.58-24.58 24.59 24.58a2 2 0 0 0 2.83-2.83l-24.59-24.58 24.58-24.59a2 2 0 0 0 -2.83-2.83l-24.58 24.59-24.59-24.58a2 2 0 0 0 -2.82 2.82l24.58 24.59-24.58 24.59a2 2 0 0 0 0 2.82z"></path></svg>
                                        </span>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>

                {activeCategoryId &&
                    <div className='mt-10'>
                        <p className='text-lg'>Topics</p>
                        <div className='mt-5'>
                            {categories.find(x => x.id === activeCategoryId).topics.map(x => {
                                return (
                                    <div key={x.id} className={activeTopicName === x.name ? "topics__item topics__item--active" : "topics__item"} onClick={() => setActiveTopicName(x.name)}>{x.name}
                                        {activeTopicName === x.name &&
                                            <span onClick={(event) => {
                                                event.stopPropagation();
                                                setActiveTopicName(null);
                                            }}>
                                                <svg id="fi_2961937" height="14" viewBox="0 0 64 64" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m4.59 59.41a2 2 0 0 0 2.83 0l24.58-24.58 24.59 24.58a2 2 0 0 0 2.83-2.83l-24.59-24.58 24.58-24.59a2 2 0 0 0 -2.83-2.83l-24.58 24.59-24.59-24.58a2 2 0 0 0 -2.82 2.82l24.58 24.59-24.58 24.59a2 2 0 0 0 0 2.82z"></path></svg>
                                            </span>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>

            <div className='block sm:hidden sidebar'>
                {/* mobile */}
            </div>
            <div className="content fadeIn w-full">
                <section id="section1">
                    <h2 className='pageTitle text-center'>Knowledge Hub</h2>
                    <p className='text-center -mt-2 sm:mt-0'>Discover, Learn, and Grow with the Blukite Finance Knowledge Hub.</p>
                    <div className='knowledgeHub__filterSection'>
                        <div className='flex gap-5 mt-0 sm:mt-10'>
                            <div className='w-full'>
                                <Input prefix={<SearchIcon style={{ marginRight: 8 }} />} value={searchValue} onKeyDown={handleKeyDown} onChange={(e) => setSearchValue(e.target.value)} size='large' placeholder='Search the Knowledge Hub for any topic or keyword.' />
                            </div>
                            <div className='w-min'>
                                <SearchButton onClick={handleSearch} text="Search" />
                            </div>
                        </div>

                        {/* <Select
                            defaultValue={1}
                            className='block sm:hidden mt-3'
                            style={{
                                width: '100%',
                            }}
                            onChange={(value) => setActiveSlideBenefits(value)}
                            options={[
                                {
                                    value: 1,
                                    label: 'Visual Impairments',
                                },
                                {
                                    value: 2,
                                    label: 'Hearing Impairments',
                                },
                                {
                                    value: 3,
                                    label: 'Motor Disabilities',
                                },
                                {
                                    value: 4,
                                    label: 'Cognitive Disabilities',
                                },
                                {
                                    value: 5,
                                    label: 'Elderly Users',
                                }
                            ]}
                        /> */}

                        <Cascader className='block sm:hidden mt-3 w-full' placeholder="Filter" size='large' options={categoriesOptions} onChange={onChange} changeOnSelect />

                    </div>
                    <div className='knowledgeHub__posts'>
                        {searchResults.map((post, index) => {
                            const category = categories.find(c => c.id === post.categoryId);
                            const topic = category?.topics.find(t => t.id === post.topicId);

                            return (
                                <div key={post.id} className='knowledgeHub__post' onClick={() => navigate(`${baseURL}/knowledge_hub/${post.id}`)}>
                                    <div className={index ? 'flex flex-col-reverse' : 'flex flex-col sm:flex-row gap-10 items-end'}>
                                        <div className={index ? 'w-full flex flex-col mt-5' : 'w-full sm:w-7/12 flex flex-col'}>
                                            <p className='text-lg sm:text-xl font-normal'>{post.title}</p>
                                            <p className='mt-2 sm:mt-3 text'>
                                                {post.description}</p>
                                            <div className='flex gap-5 mt-5'>
                                                <div className='categories__item categories__item--plain'>{category?.name}</div>
                                                <div className='topics__item topics__item--plain'>{topic?.name}</div>
                                            </div>
                                        </div>

                                        <div className={index ? 'h-44 sm:h-72 w-full sm:mt-0 flex justify-center rounded-t-2xl overflow-hidden' : 'h-44 w-full mt-5 sm:mt-0 sm:w-5/12 flex justify-center '}>
                                            {post.isVideo ?
                                            
                                            <video className='w-full object-cover' autoPlay muted loop playsInline>
                                                <source src={`${basePath}/videos/${post.videoUrl}`} type="video/mp4" />
                                            </video>

                                            : 
                                            <img
                                            className='w-full h-full object-cover'
                                            src={`${basePath}/${post.imageUrl}`}
                                            srcset={`
                                              ${basePath}/${post.imageUrlSmall} 576w,
                                              ${basePath}/${post.imageUrl} 1800w`}
                                            alt="Image for BluSys® Core: The Engine Driving Our SaaS Suite"
                                          />
                                            }
                                        </div>
                                    </div>
                                    {/* <div className='text-gray absolute bottom-5 sm:bottom-10 right-5 sm:right-10'>{post.date}</div> */}
                                </div>
                            );
                        })}

                        {!searchResults || !searchResults.length && <Empty description="There are no articles for your request" />}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default KnowledgeHub;
