import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './homePage.scss';
import Speaker from '../../components/speaker/speaker';
import { PlainButton, EmptyButton, DarkButton } from '../../components/buttons/buttons';
import { basePath, baseURL } from '../../routes';
import { openPopup } from '../../actions/popup';
import { useNavigate } from "react-router-dom";
import Helmet from 'react-helmet';

const mapStateToProps = state => ({
  ...state.ea,
});

const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  }
})


const HomePage = ({ openPopup }) => {

  let navigate = useNavigate();

  return (
    <div className='fadeIn'>

      <Helmet>
        <title>BKF</title>
        <meta name="description" content="Blukite Finance provides an expansive ecosystem tailored for progressive credit providers. Our flagship BluSys® Core Solution anchors a comprehensive suite of services, empowering banks, credit unions, and lending institutions to meet and exceed the expectations of today's digital customers with innovative, integrated financial solutions." />
        <meta name="keywords" content="home, finance, Blukite, support, services" />
        <meta name="author" content="Blukite Finance" />
        <meta property="og:title" content="Home Page - Blukite Finance" />
        <meta property="og:description" content="Blukite Finance provides an expansive ecosystem tailored for progressive credit providers. Our flagship BluSys® Core Solution anchors a comprehensive suite of services, empowering banks, credit unions, and lending institutions to meet and exceed the expectations of today's digital customers with innovative, integrated financial solutions." />
        <meta property="og:image" content={`https://blukite.finance/images/bkfWebsitePreview.jpg`} />
        <meta property="og:url" content={`https://blukite.finance`} />
      </Helmet>

      <section className='container bg-lightGray p-5 md:p-10 flex flex-col lg:flex-row items-center gap-10 sm:gap-20 mt-20 sm:mt-28 relative'>
        <div className='flex-1 bg-gray'>
          <h1 className='opacity-0 absolute pointer-events-none'>Blukite Finance transforms credit with advanced services</h1>
          <h2 className='font-normal leading-7 sm:leading-9 text-2xl sm:text-3xl md:text-4xl uppercase'>
            <span className='text-blue'> blukite finance</span><br />
            revolutionising credit services with advanced financial solutions
          </h2>
          <p className='mt-5 font-light sm:font-normal'>
            <Speaker id='1'
              text="Blukite finance,  revolutionising credit services with advanced financial solutions. Blukite Finance provides an expansive ecosystem tailored for progressive credit providers. Our flagship BluSys Core Solution anchors a comprehensive suite of services, empowering banks, credit unions, and lending institutions to meet and exceed the expectations of today's digital customers with innovative, integrated financial solutions. By leveraging our cutting-edge technology, we offer unparalleled flexibility, scalability, and efficiency. Our solutions are designed to help clients stay ahead in a competitive market, ensuring they can adapt to changing demands with ease. Experience the future of credit services with Blukite Finance, where innovation and excellence drive every solution, and customer satisfaction is paramount." />
            Blukite Finance provides an expansive ecosystem tailored for progressive credit providers. Our flagship BluSys<sup>®</sup> Core Solution anchors a comprehensive suite of services, empowering banks, credit unions, and lending institutions to meet and exceed the expectations of today's digital customers with innovative, integrated financial solutions. By leveraging our cutting-edge technology, we offer unparalleled flexibility, scalability, and efficiency. Our solutions are designed to help clients stay ahead in a competitive market, ensuring they can adapt to changing demands with ease. Experience the future of credit services with Blukite Finance, where innovation and excellence drive every solution, and customer satisfaction is paramount.</p>
          <div className='mt-5 sm:mt-7 flex flex-col sm:flex-row gap-5 w-full sm:w-max'>
            <PlainButton onClick={() => openPopup({ currentPopup: 'BookConsultation' })} text="Connect" />
            <EmptyButton onClick={() => navigate(`${baseURL}/customised_modular_services`)} text="More Info" />
            <EmptyButton onClick={() => navigate(`${baseURL}/disclaimer`)} text="Disclaimer" />
          </div>
        </div>
        <div className=' w-full sm:w-6/12 xl:w-4/12 flex items-center justify-center'>
          <div className='w-10/12 sm:w-9/12  flex flex-col items-center justify-center relative'>
            <video className=' w-full' autoPlay muted loop playsInline>
              <source src={`${basePath}/videos/animatedbulbs-blue.mp4`} type="video/mp4" />
            </video>
            <div className='p-2 relative w-32'>
              <img src={`${basePath}/images/logo-driven-bold.svg`} alt="logo" />
            </div>
          </div>
        </div>
      </section>

      <section className='solutions bg-white'>
        <div className='container py-10 md:py-16'>
          <h1 className='text-xl md:text-2xl sm:text-3xl md text-4xl uppercase'>
            <span className='text-blue'> Revolutionising the Credit Industry with BluSys<sup>®</sup></span><br />
            Your Foundation for Financial Innovation
          </h1>
          <div className='solutions__itemsWrapper mt-10 gap-5 sm:gap-10'>
            <div className='bg-lightGray'>
              <div className='h-44 sm:h-64 overflow-hidden'>

                <img
                  className='w-full h-full object-cover'
                  src={`${basePath}/videos/abstract.webp`}
                  srcset={`
                    ${basePath}/videos/abstract-small.webp 576w,
                    ${basePath}/videos/abstract.webp 1800w`}
                  alt="Image for BluSys® Core: The Engine Driving Our SaaS Suite"
                />

              </div>
              <div className='px-5 py-5'>
                <p className='text-lg font-semibold leading-5'>BluSys<sup>®</sup> Core: The Engine Driving Our SaaS Suite</p>
                <p className='text-base mt-2'>
                  <Speaker id="4" text="BluSys® Core: The Engine Driving Our SaaS Suite. At the cornerstone of Blukite Finance's service portfolio is the BluSys® Core Solution – an indispensable platform that serves as the operational heart of our comprehensive financial solutions. This foundational system ensures a seamless and integrated experience for all clients, acting as the central hub from which all our innovative services operate. Highly flexible and configurable, BluSys® Core offers the option to be either hosted by us or self-hosted, aligning perfectly with your specific operational needs and preferences. Experience the efficiency and reliability of a solution designed to adapt and grow with your business." />
                  At the cornerstone of Blukite Finance's service portfolio is the BluSys<sup>®</sup> Core Solution – an indispensable platform that serves as the operational heart of our comprehensive financial solutions. This foundational system ensures a seamless and integrated experience for all clients, acting as the central hub from which all our innovative services operate. Highly flexible and configurable, BluSys® Core offers the option to be either hosted by us or self-hosted, aligning perfectly with your specific operational needs and preferences. Experience the efficiency and reliability of a solution designed to adapt and grow with your business.</p>
              </div>
            </div>

            <div className='bg-lightGray min-w-32'>
              <div className='h-44 sm:h-64 overflow-hidden'>


                <img
                  className='w-full h-full object-cover'
                  src={`${basePath}/videos/sky.webp`}
                  srcset={`
    ${basePath}/videos/sky-small.webp 576w,
    ${basePath}/videos/sky.webp 1800w`}
                  alt="Flexible Financial Suite: Tailored
    Solutions with BluSys® Integration"/>

              </div>
              <div className='px-5 py-5'>
                <p className='text-lg font-semibold leading-5'>Blukite Finance - Flexible Financial Suite: Tailored Solutions with BluSys<sup>®</sup> Integration</p>
                <p className='text-base mt-2'>
                  <Speaker id="2" text="Blukite Finance - Flexible Financial Suite: Tailored Solutions with BluSys® Integration. Our Flexible Financial Suite extends beyond the core, offering a comprehensive range of adaptable financial solutions that seamlessly integrate with BluSys®. From regulatory compliance and customer monitoring to payment processing and bespoke marketing strategies, Blukite Finance delivers a highly configurable and synergistic array of services. This unique flexibility ensures you can tailor the perfect solution to address your institution's specific challenges and opportunities, leveraging the extensive work and advancements we have made with Blukite Finance." />
                  Our Flexible Financial Suite extends beyond the core, offering a comprehensive range of adaptable financial solutions that seamlessly integrate with BluSys<sup>®</sup>. From regulatory compliance and customer monitoring to payment processing and bespoke marketing strategies, Blukite Finance delivers a highly configurable and synergistic array of services. This unique flexibility ensures you can tailor the perfect solution to address your institution's specific challenges and opportunities, leveraging the extensive work and advancements we have made with Blukite Finance.</p>
              </div>
            </div>

            <div className='bg-lightGray'>
              <div className='h-44 sm:h-64  overflow-hidden'>
                <video className='w-full h-full object-cover' autoPlay muted loop playsInline>
                  <source src={`${basePath}/videos/businessMeeting.mp4`} type="video/mp4" />
                  <track src={`${basePath}/videos/captions_businessMeeting.vtt`} default kind="captions" srclang="en" label="English" />
                </video>

              </div>
              <div className='px-5 py-5'>
                <p className='text-lg font-semibold leading-5'>Excellence in Credit Innovation: Elevate Your Financial Services with Blukite Finance</p>
                <p className='text-base mt-2'>
                  <Speaker id="3" text="Excellence in Credit Innovation: Elevate Your Financial Services with Blukite Finance. At Blukite Finance, we are committed to excellence and innovation. We understand the intricacies of the credit industry and strive to provide solutions that not only meet but exceed regulatory standards, security protocols, and customer satisfaction benchmarks. Our advanced technology and deep industry expertise empower us to deliver superior, tailored solutions that drive growth and efficiency. Partner with us to navigate the future of credit provisioning with confidence and finesse, ensuring your institution remains at the forefront of the financial services landscape." />
                  At Blukite Finance, we are committed to excellence and innovation. We understand the intricacies of the credit industry and strive to provide solutions that not only meet but exceed regulatory standards, security protocols, and customer satisfaction benchmarks. Our advanced technology and deep industry expertise empower us to deliver superior, tailored solutions that drive growth and efficiency. Partner with us to navigate the future of credit provisioning with confidence and finesse, ensuring your institution remains at the forefront of the financial services landscape.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='container bg-lightGray p-10 flex gap-10 lg:gap-20 mt-5 mb-10 sm:mt-20 sm:mb-32 flex flex-col md:flex-row w-full'>
        <div className='w-full sm:w-10/12 lg:w-6/12'>
          <h2 className='text-xl sm:text-3xl font-medium uppercase'><span className='text-blue'>Blukite Vision:</span><br />Pioneering Tomorrow's Financial Solutions</h2>
          <div className='mt-5 md:mt-10 w-full sm:w-max'>
            <DarkButton onClick={() => navigate(`${baseURL}/tailored_services_overview`)} text="Further information" />
          </div>
        </div>
        <div className='w-full'>
          <p className='text-base sm:text-lg'>
            <Speaker id="5" text="Blukite Vision: Pioneering Tomorrow's Financial Solutions. At Blukite Finance, our vision is to pioneer the financial solutions of tomorrow, today. With BluSys® at its nucleus, we provide a robust, scalable platform that adapts seamlessly to your evolving operational needs. Whether hosted by our expert team or integrated within your existing infrastructure, BluSys® serves as the launchpad for a range of precision-engineered financial services. Our platform is designed to support your growth, enhance efficiency, and drive innovation in every aspect of your financial operations. Experience the future of finance with Blukite, where advanced technology meets unparalleled flexibility and reliability. Partner with us to stay ahead of the curve and ensure your institution is equipped for the challenges and opportunities of tomorrow. 
            At Blukite, we are convinced that true innovation means ensuring our customers have the robust, comprehensive tools they need to provide a fully inclusive financial service. We are dedicated to creating solutions that meet and exceed our customers' requirements. At Blukite, we are not just building platforms but crafting technologies that empower our customers to deliver excellence in every transaction, every day."/>
            At Blukite Finance, our vision is to pioneer the financial solutions of tomorrow, today. With BluSys<sup>®</sup> at its nucleus, we provide a robust, scalable platform that adapts seamlessly to your evolving operational needs. Whether hosted by our expert team or integrated within your existing infrastructure, BluSys<sup>®</sup> serves as the launchpad for a range of precision-engineered financial services. Our platform is designed to support your growth, enhance efficiency, and drive innovation in every aspect of your financial operations. Experience the future of finance with Blukite, where advanced technology meets unparalleled flexibility and reliability. Partner with us to stay ahead of the curve and ensure your institution is equipped for the challenges and opportunities of tomorrow.</p>
          <p className='text-base sm:text-lg mt-3'>
          At Blukite, we are convinced that true innovation means ensuring our customers have the robust, comprehensive tools they need to provide a fully inclusive financial service. We are dedicated to creating solutions that meet and exceed our customers' requirements. At Blukite, we are not just building platforms but crafting technologies that empower our customers to deliver excellence in every transaction, every day.
          </p>
          <p className='text-base sm:text-lg mt-3 text-right font-medium'>Chris Hamilton, Founder of Blukite Finance</p>
        </div>
      </section>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);