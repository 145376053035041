import React, { useState } from 'react';
import { Anchor } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Speaker from '../../components/speaker/speaker';
import './knowledgeHub.scss';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";


const KnowledgeHubPost1 = () => {
  const navigate = useNavigate();



  return (
    <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage knowledgeHubPost">

      <Helmet>
        <meta property="og:title" content="Knowledge Hub" />
        <meta property="og:description" content="Blukite Finance Support Services: Tailored Assistance for Every User" />
        <meta property="og:image" content={`https://blukite.finance/images/knowledgeHubPreview.jpg`} />
        <meta property="og:url" content={`https://blukite.finance/knowledge_hub/1`} />
      </Helmet>

      <div className="hidden sm:block sidebar">

        <div className='knowledgeHubPost__backBtn' onClick={() => navigate(`${baseURL}/knowledge_hub`)}>
          <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.13185L27 8.13185L20.0486 15.0833" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.8691 0.999967L26.9108 8.04163" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" />
          </svg>
          <p className='font-normal'>Knowledge Hub </p>
        </div>

        <Anchor
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Introduction'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'TaluCard',
            }
          ]}
        />
      </div>

      <div className='flex sm:hidden sidebar'>

        <div className='mt-0 min-w-fit mr-5' onClick={() => navigate(`${baseURL}/knowledge_hub`)}>
          <svg className='transform rotate-180' width="26" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.13185L27 8.13185L20.0486 15.0833" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.8691 0.999967L26.9108 8.04163" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" />
          </svg>
          <p className='font-normal text-sm uppercase'>Back </p>
        </div>

        <Anchor
          direction="horizontal"
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Introduction'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'TaluCard',
            }
          ]}
        />
      </div>

      <div className="content">



        <section id="section1">
          <h2 className='pageTitle'>IDEX Biometrics and TaluCard bring inclusive biometric payment solutions
            to market in Europe</h2>
          <div className='flex gap-5 items-end text mt-7 flex-wrap'>
            <div className='categories__item categories__item--plain mt-0'>News</div>
            <div className='topics__item topics__item--plain mt-0'>Press release</div>
          </div>

          <div className='mt-10'>
            <p className=''>
              <Speaker id="550" text="TaluCard, in collaboration with IDEX Biometrics and a London based issuer is launching its state-of-the-art biometric payment solution. This groundbreaking initiative is set to 
transform payment solutions with a strong focus on accessibility and security for all users, including those with visual impairments and members of the aging community."/>
              TaluCard, in collaboration with IDEX Biometrics and a London based issuer is launching its state-of-the-art biometric payment solution. This groundbreaking initiative is set to
              transform payment solutions with a strong focus on accessibility and security for all users, including those with visual impairments and members of the aging community.</p>

            <p className='text'>
              <Speaker id="551" text="TaluCard features fingerprint authentication and enhanced security as only the authorised, registered user can make transactions. Additionally, it includes tactile guidance markers 
that help users accurately locate the fingerprint sensor and voice guidance. TaluCard offers unlimited contactless transactions, removing the need for PINs or signatures, making 
transactions seamless and efficient."/>
              TaluCard features fingerprint authentication and enhanced security as only the authorised, registered user can make transactions. Additionally, it includes tactile guidance markers
              that help users accurately locate the fingerprint sensor and voice guidance. TaluCard offers unlimited contactless transactions, removing the need for PINs or signatures, making
              transactions seamless and efficient.</p>

            <video className='w-full sm:w-8/12 mx-auto object-cover mt-10' autoPlay muted loop playsInline>
              <source src={`${basePath}/videos/fingerprint.mp4`} type="video/mp4" />
            </video>
          </div>
        </section>

        <section id="section2">

          <p className=''>
            <Speaker id="551" text="The TaluCard app, will be launched on iOS and Android, providing a digital solution in support of the TaluCard for managing payments and finances. A proprietary access technology
offers discreet audio feedback for visually impaired users to verify transaction details, ensuring a secure and private user experience. TaluCard is committed to making payment solutions usable for everyone, ensuring ease of use also for those with accessibility issues. Current payment systems pose significant 
challenges for visually impaired, leading to security risks and potential fraud. With approximately 2.3 million people in the UK having some form of visual impairment, TaluCard offers
more secure and accessible payment options. The market introduction is targeted for late 2024 and its initial launch phase will provide refinement of the user experience in collaboration with interest organizations for visually 
impaired."/>
            The TaluCard app, will be launched on iOS and Android, providing a digital solution in support of the TaluCard for managing payments and finances. A proprietary access technology
            offers discreet audio feedback for visually impaired users to verify transaction details, ensuring a secure and private user experience.</p>

          <p className='text'>TaluCard is committed to making payment solutions usable for everyone, ensuring ease of use also for those with accessibility issues. Current payment systems pose significant
            challenges for visually impaired, leading to security risks and potential fraud. With approximately 2.3 million people in the UK having some form of visual impairment, TaluCard offers
            more secure and accessible payment options.</p>

          <p className='text'>The market introduction is targeted for late 2024 and its initial launch phase will provide refinement of the user experience in collaboration with interest organizations for visually
            impaired.</p>

          <p className='text'>
            <Speaker id="551" text='TaluCard is fully compliant with the UK Consumer Duty and Accessibility laws as well as the European Accessibility Act, which is set to take effect in 2025. This solution provides 
banks and fintech’s with effective and ready-to-implement means of meeting the highest standards of fairness, transparency, and inclusivity as mandated by the regulators. Steve Tyler, Director of Assistive Technology at Leonard Cheshire and former Head of Strategy at the Royal National Institute of the Blind (RNIB) says: "TaluCard is a groundbreaking 
solution that instantly removes some of the challenges of payment experiences; through the simple expedient of seamless biometric identification there is no need to be concerned 
about misuse of a card or handling unusual or inaccessible point of sale solutions. TaluCard provides a unique level of peace of mind, convenience and user experience – a great win
for accessibility”'/>
            TaluCard is fully compliant with the UK Consumer Duty and Accessibility laws as well as the European Accessibility Act, which is set to take effect in 2025. This solution provides
            banks and fintech’s with effective and ready-to-implement means of meeting the highest standards of fairness, transparency, and inclusivity as mandated by the regulators.
          </p>
          <p className='text'>Steve Tyler, Director of Assistive Technology at Leonard Cheshire and former Head of Strategy at the Royal National Institute of the Blind (RNIB) says: "TaluCard is a groundbreaking
            solution that instantly removes some of the challenges of payment experiences; through the simple expedient of seamless biometric identification there is no need to be concerned
            about misuse of a card or handling unusual or inaccessible point of sale solutions. TaluCard provides a unique level of peace of mind, convenience and user experience – a great win
            for accessibility”</p>

          <p>
            <Speaker id="551" text="Catharina Eklof, Chief Commercial Officer at IDEX Biometrics says “IDEX Biometrics long standing commitment to bringing trusted biometric authentication solutions to everyone is 
materializing with the biometric TaluCard. It is our strategic imperative to provide payment and access solutions that make it easier and more secure to pay for everyone, including 
the visually- and memory impaired. We share the conviction with TaluCard that digital and financial inclusion is critical for economic growth and should be a key priority for banks 
and fintechs”."/>Catharina Eklof, Chief Commercial Officer at IDEX Biometrics says “IDEX Biometrics long standing commitment to bringing trusted biometric authentication solutions to everyone is
            materializing with the biometric TaluCard. It is our strategic imperative to provide payment and access solutions that make it easier and more secure to pay for everyone, including
            the visually- and memory impaired. We share the conviction with TaluCard that digital and financial inclusion is critical for economic growth and should be a key priority for banks
            and fintechs”.</p>

          <p className='font-normal text-lg mt-5'>For further information contact:</p>
          <p>Samantha Morgan, Director of Market Introduction</p>
          <p>E-mail: <a className='underline hover:text-blue transition' href="mailto:sam@talucard.com">sam@talucard.com</a></p>

          <p className='font-normal text-lg mt-5'>About IDEX Biometrics</p>
          <p className='text'>IDEX Biometrics ASA (OSE: IDEX) is a global technology leader in fingerprint biometrics, offering authentication solutions across payments, access control, and digital identity. Our
            solutions bring convenience, security, peace of mind and seamless user experiences to the world. Built on patented and proprietary sensor technologies, integrated circuit designs,
            and software, our biometric solutions target card-based applications for payments and digital authentication. As an industry-enabler we partner with leading card manufacturers and
            technology companies to bring our solutions to market.</p>

          <p className='text'>
            For more information, please visit <a className='underline hover:text-blue transition' href="https://www.idexbiometrics.com" target='_blank'>www.idexbiometrics.com</a> or contact Samantha Morgan at <a className='underline hover:text-blue transition' href="mailto:sam@talucard.com">sam@talucard.com</a>
          </p>


          {/* <p className='text'>For more information, please visit <a className='underline hover:text-blue transition' href="https://www.idexbiometrics.com" target='_blank'>www.idexbiometrics.com</a> or contact Samantha Morgan at <a className='underline hover:text-blue transition' href="mailto:sam@talucard.com">sam@talucard.com</a></p> */}



        </section>

        <div className='my-10 separateLine'></div>


        <div className='mt-10 my-20 flex gap-5 items-center'>
          <p className='uppercase text-lg font-normal'>share this</p>

          <div className='flex gap-2'>

            <FacebookShareButton

              url={`https://blukite.finance/knowledge_hub/1`}
              hashtag={"#BlukiteFinance"}
            >

              <div className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_580)">
                    <path d="M32.375 0H4.625C2.07431 0 0 2.07431 0 4.625V32.375C0 34.9257 2.07431 37 4.625 37H18.5V24.2812H13.875V18.5H18.5V13.875C18.5 10.0432 21.6057 6.9375 25.4375 6.9375H30.0625V12.7188H27.75C26.4735 12.7188 25.4375 12.5985 25.4375 13.875V18.5H31.2188L28.9062 24.2812H25.4375V37H32.375C34.9257 37 37 34.9257 37 32.375V4.625C37 2.07431 34.9257 0 32.375 0Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_580">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

            </FacebookShareButton>


            <LinkedinShareButton
              url="https://blukite.finance/knowledge_hub/1"
              title="Blukite Finance Knowledge Hub"
              summary="Blukite Finance Support Services: Tailored Assistance for Every User"
              source="https://blukite.finance/knowledge_hub/1">

              <div className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_585)">
                    <path d="M33.3 0H3.7C1.665 0 0 1.665 0 3.7V33.3C0 35.335 1.665 37 3.7 37H33.3C35.335 37 37 35.335 37 33.3V3.7C37 1.665 35.335 0 33.3 0ZM11.1 31.45H5.55V14.8H11.1V31.45ZM8.325 11.655C6.475 11.655 4.995 10.175 4.995 8.325C4.995 6.475 6.475 4.995 8.325 4.995C10.175 4.995 11.655 6.475 11.655 8.325C11.655 10.175 10.175 11.655 8.325 11.655ZM31.45 31.45H25.9V21.645C25.9 20.1651 24.605 18.87 23.125 18.87C21.645 18.87 20.35 20.1651 20.35 21.645V31.45H14.8V14.8H20.35V17.02C21.275 15.54 23.31 14.43 24.975 14.43C28.49 14.43 31.45 17.39 31.45 20.905V31.45Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_585">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </LinkedinShareButton>


            <TwitterShareButton
              title={"Blukite Finance Support Services: Tailored Assistance for Every User"}
              url={`https://blukite.finance/knowledge_hub/1`}
              hashtags={["BlukiteFinance", "KnowledgeHub"]}
              related={["BlukiteFinance"]}
            >
              <div className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_590)">
                    <path d="M19.7373 17.8683L28.0637 29.7779H24.6466L17.8521 20.0596V20.059L16.8545 18.6324L8.91748 7.27908H12.3346L18.7398 16.4416L19.7373 17.8683Z" fill="white" />
                    <path d="M33.0021 0H3.99793C1.78999 0 0 1.78999 0 3.99793V33.0021C0 35.21 1.78999 37 3.99793 37H33.0021C35.21 37 37 35.21 37 33.0021V3.99793C37 1.78999 35.21 0 33.0021 0ZM23.5998 31.3767L16.7242 21.3701L8.11588 31.3767H5.89105L15.7363 19.933L5.89105 5.60428H13.4002L19.9109 15.0798L28.0624 5.60428H30.2872L20.8991 16.5173H20.8985L31.1089 31.3767H23.5998Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_590">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </TwitterShareButton>




            {/* <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_580)">
                    <path d="M32.375 0H4.625C2.07431 0 0 2.07431 0 4.625V32.375C0 34.9257 2.07431 37 4.625 37H18.5V24.2812H13.875V18.5H18.5V13.875C18.5 10.0432 21.6057 6.9375 25.4375 6.9375H30.0625V12.7188H27.75C26.4735 12.7188 25.4375 12.5985 25.4375 13.875V18.5H31.2188L28.9062 24.2812H25.4375V37H32.375C34.9257 37 37 34.9257 37 32.375V4.625C37 2.07431 34.9257 0 32.375 0Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_580">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a> */}
            {/* 
              <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_585)">
                    <path d="M33.3 0H3.7C1.665 0 0 1.665 0 3.7V33.3C0 35.335 1.665 37 3.7 37H33.3C35.335 37 37 35.335 37 33.3V3.7C37 1.665 35.335 0 33.3 0ZM11.1 31.45H5.55V14.8H11.1V31.45ZM8.325 11.655C6.475 11.655 4.995 10.175 4.995 8.325C4.995 6.475 6.475 4.995 8.325 4.995C10.175 4.995 11.655 6.475 11.655 8.325C11.655 10.175 10.175 11.655 8.325 11.655ZM31.45 31.45H25.9V21.645C25.9 20.1651 24.605 18.87 23.125 18.87C21.645 18.87 20.35 20.1651 20.35 21.645V31.45H14.8V14.8H20.35V17.02C21.275 15.54 23.31 14.43 24.975 14.43C28.49 14.43 31.45 17.39 31.45 20.905V31.45Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_585">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </a>

              <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_590)">
                    <path d="M19.7373 17.8683L28.0637 29.7779H24.6466L17.8521 20.0596V20.059L16.8545 18.6324L8.91748 7.27908H12.3346L18.7398 16.4416L19.7373 17.8683Z" fill="white" />
                    <path d="M33.0021 0H3.99793C1.78999 0 0 1.78999 0 3.99793V33.0021C0 35.21 1.78999 37 3.99793 37H33.0021C35.21 37 37 35.21 37 33.0021V3.99793C37 1.78999 35.21 0 33.0021 0ZM23.5998 31.3767L16.7242 21.3701L8.11588 31.3767H5.89105L15.7363 19.933L5.89105 5.60428H13.4002L19.9109 15.0798L28.0624 5.60428H30.2872L20.8991 16.5173H20.8985L31.1089 31.3767H23.5998Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_590">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a> */}

          </div>
        </div>


        {/* <section className='my-20'>
          <div className='flex justify-between'>
            <p className='text-lg font-normal'>Keep exploring</p>
          </div>

          <div className='flex my-5'>
            <div className='p-7 border border-separateLight rounded-xl flex h-80 w-3/12 cursor-pointer knowledgeHub__post'>
              <div className='mt-auto'>
                <p className='font-normal text-lg'>Blukite Finance Support Services </p>
                <p className='text'>At Blukite Finance, we understand the value of 
exceptional support. </p>
<div className='flex gap-5 mt-auto'>
                                                <div className='categories__item categories__item--plain'>Tutorials & Explainers</div>
                                                <div className='topics__item topics__item--plain'>Explainer</div>
                                            </div>
              </div>

            </div>
          </div>
          
        </section> */}



      </div>
    </div>
  );
}

export default KnowledgeHubPost1