import React, { useEffect } from 'react';
import { Anchor } from 'antd';
import { basePath } from '../../routes';
import { useLocation } from 'react-router-dom';
import Speaker from '../../components/speaker/speaker';
import { PlainButton } from '../../components/buttons/buttons';
import './leadershipTeam.scss';

const LeadershipTeam = () => {

    const location = useLocation();

    useEffect(() => {
        const scrollToHash = () => {
            const hash = location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        scrollToHash();
    }, [location.hash]);

    return (
        <div className="flex flex-col md:flex-row container gap-16 mt-0 sm:mt-24 lg:mt-24 infoPage">
            <div className="hidden sm:block sidebar">
                <Anchor
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Leadership Team'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Careers',
                        }
                    ]}
                />
            </div>

            <div className='block sm:hidden sidebar'>
                <Anchor
                    direction="horizontal"
                    targetOffset={150}
                    items={[
                        {
                            key: 'section1',
                            href: '#section1',
                            title: 'Leadership Team'
                        },
                        {
                            key: 'section2',
                            href: '#section2',
                            title: 'Careers',
                        }
                    ]}
                />
            </div>
            <div className="content fadeIn">
                <section id="section1">
                    <h2 className='pageTitle'>Leading with Innovation and Growth</h2>
                    <p className='text'>
                        <Speaker id="15" text="Leading with Innovation and Growth. At Blukite Finance, our leadership team embodies our commitment to innovation and growth. Guiding traditional banks, FinTechs, and consumer lenders alike, we leverage our
                        modular services to modernise and expand financial offerings. Our leadership team stands ready to be your partners, focusing on sustainable growth and profitability. With a
                        balanced approach to innovation and practical strategy, we are here to support your financial services evolution."/>
                        At Blukite Finance, our leadership team embodies our commitment to innovation and growth. Guiding traditional banks, FinTechs, and consumer lenders alike, we leverage our
                        modular services to modernise and expand financial offerings. Our leadership team stands ready to be your partners, focusing on sustainable growth and profitability. </p>
                    <p>With a
                        balanced approach to innovation and practical strategy, we are here to support your financial services evolution. Meet the visionaries driving forward the dynamic world of consumer lending.</p>

                    <p className='subtitle italic'>Meet the <span className='font-normal'>visionaries driving forward</span> the dynamic world of consumer lending.</p>

                    <div className='leadershipTeam__cards my-7'>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>

                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/JohnSchratz.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/JohnSchratz-small.jpg 576w,
                    ${basePath}/images/team/new/JohnSchratz.jpg 1800w`}
                                    alt="John Schratz" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>John Schratz</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/johnschratz/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/johnschratz/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>

                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/IanVickers.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/IanVickers-small.jpg 576w,
                    ${basePath}/images/team/new/IanVickers.jpg 1800w`}
                                    alt="Ian Vickers" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Ian Vickers</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/ianvickers/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/ianvickers/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>
                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/ChristopherHamilton.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/ChristopherHamilton-small.jpg 576w,
                    ${basePath}/images/team/new/ChristopherHamilton.jpg 1800w`}
                                    alt="Christopher J. Hamilton" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Christopher J. Hamilton</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/christopher-hamilton-7aab1a129/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/christopher-hamilton-7aab1a129/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>

                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/PaulVaughan.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/PaulVaughan-small.jpg 576w,
                    ${basePath}/images/team/new/PaulVaughan.jpg 1800w`}
                                    alt="Paul Vaughan" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Paul Vaughan</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/paul-vaughan-b5b2751a/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/paul-vaughan-b5b2751a/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>
                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/DarynaPentsak.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/DarynaPentsak-small.jpg 576w,
                    ${basePath}/images/team/new/DarynaPentsak.jpg 1800w`}
                                    alt="Daryna Pentsak" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Daryna Pentsak</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/daryna-pentsak-b617a9218/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/daryna-pentsak-b617a9218/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>

                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/SamanthaMorgan.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/SamanthaMorgan-small.jpg 576w,
                    ${basePath}/images/team/new/SamanthaMorgan.jpg 1800w`}
                                    alt="Samantha Morgan" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Samantha Morgan</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/samantha-saidi-morgan-78a99015b/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/samantha-saidi-morgan-78a99015b/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>
                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/AbbasRizvi.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/AbbasRizvi-small.jpg 576w,
                    ${basePath}/images/team/new/AbbasRizvi.jpg 1800w`}
                                    alt="Abbas Rizvi" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Abbas Rizvi</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/abbas-rizvi-5318b5b3/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/abbas-rizvi-5318b5b3/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>
                        <div className='leadershipTeam__card'>
                            <div className='leadershipTeam__photoWrapper'>

                                <img
                                    className='w-full h-full object-cover'
                                    src={`${basePath}/images/team/new/CraigPickles.jpg`}
                                    srcset={`
                     ${basePath}/images/team/new/CraigPickles-small.jpg 576w,
                    ${basePath}/images/team/new/CraigPickles.jpg 1800w`}
                                    alt="Craig Pickles" />

                            </div>
                            <p className='my-3 font-medium text-sm sm:text-base'>Craig Pickles</p>
                            <div className='w-full hidden sm:block'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/craig-pickles-602771b5/", "_blank")} text="View LinkedIn" />
                            </div>
                            <div className='w-full block sm:hidden'>
                                <PlainButton onClick={() => window.open("https://www.linkedin.com/in/craig-pickles-602771b5/", "_blank")} text="LinkedIn" />
                            </div>
                        </div>

                    </div>
                </section>

                <section id="section2" className='my-20 mb-24'>
                    <h2 className='pageTitle'>Join Our Journey and Shape the Future of Finance with Blukite Finance</h2>
                    <p className='text'> <Speaker id="16" text="Join Our Journey and Shape the Future of Finance with Blukite Finance. After three years of meticulous development with our dedicated team of 20, Blukite Finance is poised for a pivotal phase of growth as we prepare to enter the market. Our
                        mission, to revolutionise the financial services industry through innovative and inclusive solutions remains at the core of our expansion. We are seeking passionate individuals
                        who are eager to contribute to this journey, driving forward our vision of accessible and equitable financial services for all."/>After three years of meticulous development with our dedicated team of 20, Blukite Finance is poised for a pivotal phase of growth as we prepare to enter the market. Our
                        mission, to revolutionise the financial services industry through innovative and inclusive solutions remains at the core of our expansion. We are seeking passionate individuals
                        who are eager to contribute to this journey, driving forward our vision of accessible and equitable financial services for all. </p>

                    <div className='customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden mt-10'>
                        <img className='hidden xl:block absolute right-0 bottom-0' data-aos="fade-up-left" data-aos-duration="800" src={`${basePath}/images/leadershipTeam_1.svg`} alt="illustration Accelerating Growth and Learning at Blukite Finance" />
                        <div className='w-full xl:w-8/12 2xl:w-9/12'>
                            <img width="40" height="40" src={`${basePath}/images/ico/blueCircles/1.svg`} alt="icons for Unlock Your Potential: Accelerating Growth and Learning at Blukite Finance" />
                            <p className='font-normal text-lg mt-5 '>Unlock Your Potential: Accelerating Growth and Learning at Blukite Finance</p>
                            <p className='mt-2 leading-tight'><Speaker id="17" text="Unlock Your Potential: Accelerating Growth and Learning at Blukite Finance. At Blukite Finance, as we embark on our market launch, we are at an exciting juncture that promises rapid growth and unparalleled learning opportunities. This phase is not just about scaling our operations but also about enriching our team's expertise and impact. Joining us now means you will be part of a transformative journey, where your contributions directly influence our trajectory and success. We value innovation, creativity, and the drive to exceed expectations, offering a dynamic environment where every team member plays a critical role in our collective achievements." />At Blukite Finance, as we embark on our market launch, we are at an exciting juncture that promises rapid growth and unparalleled learning opportunities. This phase is not just about scaling our operations but also about enriching our team's expertise and impact. Joining us now means you will be part of a transformative journey, where your contributions directly influence our trajectory and success. We value innovation, creativity, and the drive to exceed expectations, offering a dynamic environment where every team member plays a critical role in our collective achievements.</p>
                        </div>
                    </div>
                    <div className='flex flex-col xl:flex-row mt-7 gap-7'>
                        <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 relative overflow-hidden w-full xl:w-auto' data-aos="fade-up-right" data-aos-duration="800">
                            <img width="40" height="40" src={`${basePath}/images/ico/blueCircles/2.svg`} alt="icons for Empowering Innovation" />
                            <p className='xl:w-9/12 font-normal text-lg mt-5 leading-tight'>Empowering Innovation<br />
                                Our Culture of Flexibility and Drive at Blukite Finance</p>
                            <div className='w-full'>
                                <p className='mt-2 leading-tight'><Speaker id="18" text="Empowering Innovation. Our Culture of Flexibility and Drive at Blukite Finance. Our culture thrives on autonomy and the spirit of entrepreneurship. We have cultivated a hybrid working environment that champions flexibility and innovation, enabling our team to work where they are most productive, whether at home or in the office. We are looking for self-starters, dynamic individuals who can independently identify and tackle necessary tasks, driving projects forward with passion and precision. Our environment is designed for those who flourish when given the freedom to innovate, offering the perfect blend of support and autonomy to achieve breakthroughs." />Our culture thrives on autonomy and the spirit of entrepreneurship. We have cultivated a hybrid working environment that champions flexibility and innovation, enabling our team to work where they are most productive, whether at home or in the office. We are looking for self-starters, dynamic individuals who can independently identify and tackle necessary tasks, driving projects forward with passion and precision. Our environment is designed for those who flourish when given the freedom to innovate, offering the perfect blend of support and autonomy to achieve breakthroughs.</p>
                            </div>
                        </div>
                        <div className='flex-1 customShadow rounded-3xl p-5 sm:p-10 w-full xl:w-auto' data-aos="fade-up" data-aos-duration="800">
                            <img width="40" height="40" src={`${basePath}/images/ico/TailoredServicesBanks/3.svg`} alt="icons for Explore Your Future
Join Our Expanding Team at Blukite Finance" />
                            <p className='font-normal text-lg mt-5 leading-tight'>Explore Your Future<br />
                                Join Our Expanding Team at Blukite Finance</p>
                            <p className='mt-2 leading-tight'><Speaker id="19" text="Explore Your Future Join Our Expanding Team at Blukite Finance. As Blukite Finance gears up for market entry, we are on the lookout for individuals across a spectrum of roles and talents to join our pioneering team. While we are open to a variety of positions, we particularly value expertise in areas such as product development, financial analysis, customer experience, and technology innovation. If you are driven, adaptable, and ready to contribute to a game-changing financial services venture, we encourage you to reach out. " /> As Blukite Finance gears up for market entry, we are on the lookout for individuals across a spectrum of roles and talents to join our pioneering team. While we are open to a variety of positions, we particularly value expertise in areas such as product development, financial analysis, customer experience, and technology innovation. If you are driven, adaptable, and ready to contribute to a game-changing financial services venture, we encourage you to reach out. </p>
                        </div>
                    </div>
                    <div className='customShadow rounded-3xl p-5 sm:p-10 mt-7 relative overflow-hidden' data-aos="fade-up" data-aos-duration="800">
                        <img className='leadershipTeam__waveImg' src={`${basePath}/images/waves.svg`} alt="illustration for System Integration Solutions" />
                        <img width="40" height="40" src={`${basePath}/images/ico/TailoredServicesBuildingSocieties/1.svg`} alt="icons for Enhancing Consumer Lending with Blukite's Innovative Solutions" />
                        <p className='font-normal text-lg mt-5 '>Enhancing Consumer Lending with Blukite's Innovative Solutions</p>
                        <p className='mt-2 mb-24 leading-tight'><Speaker id="20" text="Enhancing Consumer Lending with Blukite's Innovative Solutions. We are excited to hear from innovative minds eager to join our journey. To apply, simply send your CV and a brief cover letter expressing your interest and how you envision contributing to our team to careers@blukitefinance.com. Whether you are applying for an open role or pitching your unique skills, we are open to exploring potential fits within our dynamic team." />We are excited to hear from innovative minds eager to join our journey. To apply, simply send your CV and a brief cover letter expressing your interest and how you envision contributing to our team to careers@blukitefinance.com. Whether you are applying for an open role or pitching your unique skills, we are open to exploring potential fits within our dynamic team.</p>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default LeadershipTeam