import React, { useEffect, useState, useRef } from 'react';
import './mobileMenu.scss';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { baseURL, basePath } from '../../routes';
import Search from '../search/search';

const MobileMenu = () => {
  const [activeMenuItem, setActiveMenuItem] = useState();
  const navigate = useNavigate();

  const [menuItems, setMenuItems] = useState([
    {
      label: 'About Us',
      key: 'about',
      subItems: [
        {
          label: 'Company Overview',
          id: 1,
          children: [
            {
              label: 'About Us',
              key: `${baseURL}/company_overview`,
            },
            {
              label: 'Leadership Team & Careers',
              key: `${baseURL}/leadership_team`,
            },
            {
              label: 'Diversity & Inclusion',
              key: `${baseURL}/diversity_and_inclusion`,
            }
          ]
        },
      ]
    },
    {
      label: 'ESG at Blukite',
      key: 'esg',
    },
    {
      label: 'Our Solutions',
      key: 'solutions',
      subItems: [
        {
          label: 'BluSys Core Solution',
          id: 1,
          children: [
            {
              label: 'Key Services',
              key: `${baseURL}/core_solutions`,
            },
            {
              label: 'Key Features',
              key: `${baseURL}/core_solutions#section3`,
            },
          ]
        },
        {
          label: 'Bespoke Financial Services',
          id: 3,
          children: [
            {
              label: 'Our Services',
              key: `${baseURL}/customised_modular_services`,
            },
            {
              label: 'Risk Assessment & Credit Scoring',
              key: `${baseURL}/risk_assessment_and_credit_scoring`,
            },
            {
              label: 'Underwriting & Loan Origination',
              key: `${baseURL}/underwriting&loan_origination`,
            },
            {
              label: 'Payment Processing',
              key: `${baseURL}/payment_processing`,
            },
            {
              label: 'Customer Monitoring',
              key: `${baseURL}/custmomer_monitoring`,
            },
            {
              label: 'Debt Collection',
              key: `${baseURL}/debt_collection`,
            },
            {
              label: 'Data Security & Privacy',
              key: `${baseURL}/data_security&privacy`,
            },
            {
              label: 'Marketing & Customer Acquisition',
              key: `${baseURL}/marketing&customer_acquisition`,
            },
            {
              label: 'Financial Management',
              key: `${baseURL}/financial_management`,
            },
            {
              label: 'Product Development & Market Analysis',
              key: `${baseURL}/product_development&market_analysis`,
            },
            {
              label: 'Card Issuing & Fulfilment',
              key: `${baseURL}/card_issuing&fulfilment`,
            },
            {
              label: 'White Label Payment App',
              key: `${baseURL}/white_label_consumer_app`,
            },
            {
              label: 'Loyalty & Rewards Program',
              key: `${baseURL}/loyalty&rewards_program`,
            },
          ]
        }
      ]
    },
    {
      label: 'Tailored Services',
      key: 'tailored',
      subItems: [
        {
          label: 'Tailored Services',
          id: 1,
          children: [
            {
              label: 'Overview',
              key: `${baseURL}/tailored_services_overview`,
            },
            {
              label: 'For Banks',
              key: `${baseURL}/tailored_services_banks`,
            },
            {
              label: "For Building Society's",
              key: `${baseURL}/strategic_technology_integration_for_building_societies`,
            },
            {
              label: 'For Credit Unions',
              key: `${baseURL}/growth_and_innovation_for_credit_unions`,
            },
            {
              label: 'For FinTechs',
              key: `${baseURL}/fin_techs_credit_solutions`,
            },
            {
              label: 'For Underwriters',
              key: `${baseURL}/liquidity_providers_credit_solutions`,
            },
            {
              label: 'For Retailers',
              key: `${baseURL}/tailored_services_retailers`,
            },
          ]
        },
      ]
    },
    // {
    //   label: 'Knowledge Hub',
    //   key: 'knowledge_hub',
    // },
    {
      label: 'Contact Us',
      key: 'contact_us',
    }
  ]);

  const buttonRef = useRef(null);

  const closeMenu = () => {
    if (buttonRef.current) {
      buttonRef.current.focus();
      buttonRef.current.blur();
    }
  };

  return (
    <div className="page">
      <div className='mobileMenuWrapper'>
        <Link to={{ pathname: baseURL }}><img className='w-24 block' src={`${basePath}images/logo.svg`} alt="logo" /></Link>
      </div>

      <div id="nav-container" tabIndex="1" ref={buttonRef}>
        <div className="bg"></div>
        <div className='mobileMenu flex items-center justify-between'>
          <div className="button" tabIndex="0">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
        </div>
        <div id="nav-contentWrapper" tabIndex="0">
          <div id="nav-content" tabIndex="0">
            {activeMenuItem ? (
              <>
                <div className='flex text-blue px-3' onClick={() => setActiveMenuItem()}>
                  <img className='w-2' src={`${basePath}images/ico/carret-left-blue.svg`} alt="back button" />
                  <div className='ml-3 font-semibold' onClick={() => setActiveMenuItem()}>BACK</div>
                </div>

                <div className='px-5 mt-3 font-semibold uppercase'>{activeMenuItem.label}</div>
                {activeMenuItem.subItems && activeMenuItem.subItems.map(x => (
                  <div key={x.id} className='mobileMenuSubitem'>
                    <div className=''>
                      <div className='font-normal px-5'>{x.label}</div>
                      <div className='flex flex-col'>
                        {x.children.map(y => (
                          <div className='' key={y.key}>
                            <NavLink
                              onClick={() => closeMenu()}
                              to={{ pathname: y.key.split('#')[0], hash: y.key.split('#')[1] || '' }}
                              className={({ isActive }) =>
                                isActive ? "text-blue mobileMenuItem" : "mobileMenuItem"
                              }
                            >
                              <div>{y.label}</div>
                              <img src={`${basePath}images/ico/carret-right.svg`} alt="menu item arrow icon" />
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {menuItems.map(x => (
                  <div key={x.key} className='text-lg'>
                    <div className='mobileMenuItemWrapper' onClick={() => {
                      // if (x.key === 'knowledge_hub') {
                      //   navigate(`${baseURL}/knowledge_hub`);
                      //   closeMenu();
                      // } 
                      if (x.key === 'esg') {
                        navigate(`${baseURL}/esg`);
                        closeMenu();
                      }
                      else if (x.key === 'contact_us') {
                        navigate(`${baseURL}/contact_us`);
                        closeMenu();
                      }
                      else {
                        setActiveMenuItem(x);
                      }
                    }}>
                      <div className='mobileMenuItem'>
                        <div>{x.label}</div>
                        <img src={`${basePath}images/ico/carret-right.svg`} alt="menu item arrow icon" />
                      </div>
                    </div>
                  </div>
                ))}
                <Search />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu;
