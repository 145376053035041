import React, { useState } from 'react';
import { Anchor } from 'antd';
import { basePath, baseURL } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Speaker from '../../components/speaker/speaker';
import './knowledgeHub.scss';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";


const KnowledgeHubPost1 = () => {
  const navigate = useNavigate();

  const solutionsItems = [
    {
      id: 1, title: 'Tactile Guidance Markers (TGM)', description: `Ensure accurate placement of fingers on sensors, enhancing usability and 
accessibility. TGMs reduce errors and promote muscle memory development.`, imageUrl: 'images/blog/hand1.svg'
    },
    { id: 2, title: 'Biometric Security', description: `Fingerprint authentication eliminates the need for PINs, reducing fraud risks.`, imageUrl: 'images/blog/hand2.svg' },
    {
      id: 3, title: 'Visual Impaired Access ', description: `Provides private, secure, and discreet transaction information, improving 
overall security and accessibility.`, imageUrl: 'images/blog/hand3.svg'
    },
  ]
  const [currentSolutionsItem, setCurrentSolutionsItem] = useState(solutionsItems.find(x => x.id === 1));

  return (
    <div className="flex flex-col md:flex-row container gap-16 sm:gap-5 md:gap-16 mt-0 sm:mt-12 md:mt-24 infoPage knowledgeHubPost">

      <Helmet>
        <meta property="og:title" content="Knowledge Hub" />
        <meta property="og:description" content="Blukite Finance Support Services: Tailored Assistance for Every User" />
        <meta property="og:image" content={`https://blukite.finance/images/knowledgeHubPreview.jpg`} />
        <meta property="og:url" content={`https://blukite.finance/knowledge_hub/1`} />
      </Helmet>

      <div className="hidden sm:block sidebar">

        <div className='knowledgeHubPost__backBtn' onClick={() => navigate(`${baseURL}/knowledge_hub`)}>
          <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.13185L27 8.13185L20.0486 15.0833" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.8691 0.999967L26.9108 8.04163" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" />
          </svg>
          <p className='font-normal'>Knowledge Hub </p>
        </div>

        <Anchor
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Overview'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'Our Solution',
            }
          ]}
        />
      </div>

      <div className='flex sm:hidden sidebar'>

        <div className='mt-0 min-w-fit mr-5' onClick={() => navigate(`${baseURL}/knowledge_hub`)}>
          <svg className='transform rotate-180' width="26" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.13185L27 8.13185L20.0486 15.0833" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.8691 0.999967L26.9108 8.04163" stroke="#0E2A38" stroke-width="0.9911" stroke-miterlimit="10" stroke-linecap="round" />
          </svg>
          <p className='font-normal text-sm uppercase'>Back </p>
        </div>

        <Anchor
          direction="horizontal"
          targetOffset={150}
          items={[
            {
              key: 'section1',
              href: '#section1',
              title: 'Overview'
            },
            {
              key: 'section2',
              href: '#section2',
              title: 'Our Solution',
            }
          ]}
        />
      </div>

      <div className="content">



        <section id="section1">
          <h2 className='pageTitle'>Introducing our Innovative Inclusive Payment Solution: TaluTouch</h2>
          <div className='flex gap-5 items-end text mt-7 flex-wrap'>
            <div className='categories__item categories__item--plain mt-0'>Publications</div>
            <div className='topics__item topics__item--plain mt-0'>Case Study</div>
          </div>

          <div className='mt-10'>
            <p className=''>
              <Speaker id="550" text="TaluCard, supported by Blukite Finance Limited, focuses on inclusive and accessible payment solutions. Launch slated for Q3 2024, with features designed for visually impaired and 
sighted users."/>
              TaluCard, supported by Blukite Finance Limited, focuses on inclusive and accessible payment solutions. Launch slated for Q3 2024, with features designed for visually impaired and
              sighted users.</p>


            <p className='text-lg font-normal mt-7'>The Problem Addressed</p>
            <p className='mt-2 text'>
              <Speaker id="551" text="The Problem Addressed. Visually impaired individuals encounter substantial difficulties with existing payment systems, which often lead to heightened security risks and susceptibility to fraud. In the UK, 
approximately 350,000 people are registered as legally blind, with around 171,000 classified as completely blind, and about 2.28 million individuals have some form of visual 
impairment. On a global scale, around 2.2 billion people experience varying degrees of vision impairment."/>
              Visually impaired individuals encounter substantial difficulties with existing payment systems, which often lead to heightened security risks and susceptibility to fraud. In the UK,
              approximately 350,000 people are registered as legally blind, with around 171,000 classified as completely blind, and about 2.28 million individuals have some form of visual
              impairment. On a global scale, around 2.2 billion people experience varying degrees of vision impairment.</p>

          </div>
        </section>
        <section id="section2">
          <p className='uppercase text-center font-normal text-lg mt-10'>Solutions Provided by TaluTOUCH</p>
          <video className='w-full object-cover mt-10' autoPlay muted loop playsInline>
            <source src={`${basePath}/videos/TaluDemo.mp4`} type="video/mp4" />
          </video>

          <div className='w-full xl:w-8/12 rounded-3xl p-0 lg:p-3 flex flex-col lg:flex-row switcherShadow mx-auto mt-20 sm:mt-28 text-center gap-5'>
            <p className={currentSolutionsItem.id === 1 ? 'font-normal text-lg py-2 px-5 rounded-3xl cursor-pointer bg-blue text-white flex-1 whitespace-nowrap	border border-transparent lg:border-none' : 'border border-blue lg:border-none text-blue lg:text-darkBlue font-normal text-lg py-2 px-5 flex-1 whitespace-nowrap rounded-3xl cursor-pointer hover:text-blue transition'} onClick={() => setCurrentSolutionsItem(solutionsItems.find(x => x.id === 1))}>Tactile Guidance Markers</p>
            <p className={currentSolutionsItem.id === 2 ? 'font-normal text-lg py-2 px-5 rounded-3xl cursor-pointer bg-blue text-white flex-1 whitespace-nowrap	border border-transparent lg:border-none' : 'border border-blue lg:border-none text-blue lg:text-darkBlue font-normal text-lg py-2 px-5 flex-1 whitespace-nowrap rounded-3xl cursor-pointer hover:text-blue transition'} onClick={() => setCurrentSolutionsItem(solutionsItems.find(x => x.id === 2))}>Biometric Security</p>
            <p className={currentSolutionsItem.id === 3 ? 'font-normal text-lg py-2 px-5 rounded-3xl cursor-pointer bg-blue text-white flex-1 whitespace-nowrap	border border-transparent lg:border-none' : 'border border-blue lg:border-none text-blue lg:text-darkBlue font-normal text-lg py-2 px-5 flex-1 whitespace-nowrap rounded-3xl cursor-pointer hover:text-blue transition'} onClick={() => setCurrentSolutionsItem(solutionsItems.find(x => x.id === 3))}>VIA Technology</p>
          </div>

          <div className='flex w-full xl:w-10/12 mx-auto mt-2 lg:mt-10 items-center gap-10 sm:gap-20 '>
            <div className='flex-1'>
              <p className='font-normal uppercase text-lg'>{currentSolutionsItem.title}</p>
              <p className='text'>{currentSolutionsItem.description}</p>
            </div>
            <div className='flex-1'>
              <img className='h-96 ' src={`${basePath}/${currentSolutionsItem.imageUrl}`} />
            </div>
          </div>

          <p className='text-lg font-normal mt-7'>Key Features of TaluTouch:</p>
          <p className='text mt-3'><span className='font-normal'>The TaluTouch Biometric Payment Card</span> features fingerprint authentication to enhance security by ensuring only authorized users can make transactions. It supports unlimited
            contactless transactions, removing the need for PINs or signatures and allowing for transactions of any value. Additionally, the card is equipped with Tactile Guidance Markers
            (TGM), which provide tactile reference points, including Braille-inspired indicators. These markers enable accurate fingerprint sensor location for both sighted and visually impaired
            users. TGMs also help reduce errors and foster muscle memory development, ultimately enhancing user confidence and efficiency over time.</p>

          <p className='text mt-5'><span className='font-normal'>The Virtual Card</span> offers seamless integration for both iOS and Android, providing a digital-first solution for managing finances. Users have the option to request a physical
            card if needed. The digital-first approach also supports sustainability efforts by reducing plastic waste, making it an environmentally friendly choice.</p>

          <p className='text mt-5'><span className='font-normal'>The Visual Impaired Access (VIA)</span> feature enhances accessibility by offering discreet audio feedback for visually impaired users to verify transaction details. Additionally, it allows
            for a customisable user experience, enabling users to tailor transaction processes for greater convenience.</p>

          <p onClick={() => { navigate(`${baseURL}/contact_us`) }} className='transition mt-10 cursor-pointer hover:border-b-blue hover:text-blue border-b border-1 border-b-transparent w-fit'>For more detailed information, visit TaluTouch.</p>


        </section>

        <div className='my-10 separateLine'></div>

        <section>
          <div className='mt-10 my-20 flex gap-5 items-center'>
            <p className='uppercase text-lg font-normal'>share this</p>

            <div className='flex gap-2'>

              <FacebookShareButton

                url={`https://blukite.finance/knowledge_hub/1`}
                hashtag={"#BlukiteFinance"}
              >

                <div className='knowledgeHubPost__socialIcon'>
                  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_872_580)">
                      <path d="M32.375 0H4.625C2.07431 0 0 2.07431 0 4.625V32.375C0 34.9257 2.07431 37 4.625 37H18.5V24.2812H13.875V18.5H18.5V13.875C18.5 10.0432 21.6057 6.9375 25.4375 6.9375H30.0625V12.7188H27.75C26.4735 12.7188 25.4375 12.5985 25.4375 13.875V18.5H31.2188L28.9062 24.2812H25.4375V37H32.375C34.9257 37 37 34.9257 37 32.375V4.625C37 2.07431 34.9257 0 32.375 0Z" fill="#36a9e0" />
                    </g>
                    <defs>
                      <clipPath id="clip0_872_580">
                        <rect width="37" height="37" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

              </FacebookShareButton>


              <LinkedinShareButton
                url="https://blukite.finance/knowledge_hub/1"
                title="Blukite Finance Knowledge Hub"
                summary="Blukite Finance Support Services: Tailored Assistance for Every User"
                source="https://blukite.finance/knowledge_hub/1">

                <div className='knowledgeHubPost__socialIcon'>
                  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_872_585)">
                      <path d="M33.3 0H3.7C1.665 0 0 1.665 0 3.7V33.3C0 35.335 1.665 37 3.7 37H33.3C35.335 37 37 35.335 37 33.3V3.7C37 1.665 35.335 0 33.3 0ZM11.1 31.45H5.55V14.8H11.1V31.45ZM8.325 11.655C6.475 11.655 4.995 10.175 4.995 8.325C4.995 6.475 6.475 4.995 8.325 4.995C10.175 4.995 11.655 6.475 11.655 8.325C11.655 10.175 10.175 11.655 8.325 11.655ZM31.45 31.45H25.9V21.645C25.9 20.1651 24.605 18.87 23.125 18.87C21.645 18.87 20.35 20.1651 20.35 21.645V31.45H14.8V14.8H20.35V17.02C21.275 15.54 23.31 14.43 24.975 14.43C28.49 14.43 31.45 17.39 31.45 20.905V31.45Z" fill="#36a9e0" />
                    </g>
                    <defs>
                      <clipPath id="clip0_872_585">
                        <rect width="37" height="37" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </LinkedinShareButton>


              <TwitterShareButton
                title={"Blukite Finance Support Services: Tailored Assistance for Every User"}
                url={`https://blukite.finance/knowledge_hub/1`}
                hashtags={["BlukiteFinance", "KnowledgeHub"]}
                related={["BlukiteFinance"]}
              >
                <div className='knowledgeHubPost__socialIcon'>
                  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_872_590)">
                      <path d="M19.7373 17.8683L28.0637 29.7779H24.6466L17.8521 20.0596V20.059L16.8545 18.6324L8.91748 7.27908H12.3346L18.7398 16.4416L19.7373 17.8683Z" fill="white" />
                      <path d="M33.0021 0H3.99793C1.78999 0 0 1.78999 0 3.99793V33.0021C0 35.21 1.78999 37 3.99793 37H33.0021C35.21 37 37 35.21 37 33.0021V3.99793C37 1.78999 35.21 0 33.0021 0ZM23.5998 31.3767L16.7242 21.3701L8.11588 31.3767H5.89105L15.7363 19.933L5.89105 5.60428H13.4002L19.9109 15.0798L28.0624 5.60428H30.2872L20.8991 16.5173H20.8985L31.1089 31.3767H23.5998Z" fill="#36a9e0" />
                    </g>
                    <defs>
                      <clipPath id="clip0_872_590">
                        <rect width="37" height="37" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </TwitterShareButton>




              {/* <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_580)">
                    <path d="M32.375 0H4.625C2.07431 0 0 2.07431 0 4.625V32.375C0 34.9257 2.07431 37 4.625 37H18.5V24.2812H13.875V18.5H18.5V13.875C18.5 10.0432 21.6057 6.9375 25.4375 6.9375H30.0625V12.7188H27.75C26.4735 12.7188 25.4375 12.5985 25.4375 13.875V18.5H31.2188L28.9062 24.2812H25.4375V37H32.375C34.9257 37 37 34.9257 37 32.375V4.625C37 2.07431 34.9257 0 32.375 0Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_580">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a> */}
              {/* 
              <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_585)">
                    <path d="M33.3 0H3.7C1.665 0 0 1.665 0 3.7V33.3C0 35.335 1.665 37 3.7 37H33.3C35.335 37 37 35.335 37 33.3V3.7C37 1.665 35.335 0 33.3 0ZM11.1 31.45H5.55V14.8H11.1V31.45ZM8.325 11.655C6.475 11.655 4.995 10.175 4.995 8.325C4.995 6.475 6.475 4.995 8.325 4.995C10.175 4.995 11.655 6.475 11.655 8.325C11.655 10.175 10.175 11.655 8.325 11.655ZM31.45 31.45H25.9V21.645C25.9 20.1651 24.605 18.87 23.125 18.87C21.645 18.87 20.35 20.1651 20.35 21.645V31.45H14.8V14.8H20.35V17.02C21.275 15.54 23.31 14.43 24.975 14.43C28.49 14.43 31.45 17.39 31.45 20.905V31.45Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_585">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </a>

              <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='knowledgeHubPost__socialIcon'>
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_872_590)">
                    <path d="M19.7373 17.8683L28.0637 29.7779H24.6466L17.8521 20.0596V20.059L16.8545 18.6324L8.91748 7.27908H12.3346L18.7398 16.4416L19.7373 17.8683Z" fill="white" />
                    <path d="M33.0021 0H3.99793C1.78999 0 0 1.78999 0 3.99793V33.0021C0 35.21 1.78999 37 3.99793 37H33.0021C35.21 37 37 35.21 37 33.0021V3.99793C37 1.78999 35.21 0 33.0021 0ZM23.5998 31.3767L16.7242 21.3701L8.11588 31.3767H5.89105L15.7363 19.933L5.89105 5.60428H13.4002L19.9109 15.0798L28.0624 5.60428H30.2872L20.8991 16.5173H20.8985L31.1089 31.3767H23.5998Z" fill="#36a9e0" />
                  </g>
                  <defs>
                    <clipPath id="clip0_872_590">
                      <rect width="37" height="37" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

              </a> */}

            </div>
          </div>
        </section>


        {/* <section className='my-20'>
          <div className='flex justify-between'>
            <p className='text-lg font-normal'>Keep exploring</p>
          </div>

          <div className='flex my-5'>
            <div className='p-7 border border-separateLight rounded-xl flex h-80 w-3/12 cursor-pointer knowledgeHub__post'>
              <div className='mt-auto'>
                <p className='font-normal text-lg'>Blukite Finance Support Services </p>
                <p className='text'>At Blukite Finance, we understand the value of 
exceptional support. </p>
<div className='flex gap-5 mt-auto'>
                                                <div className='categories__item categories__item--plain'>Tutorials & Explainers</div>
                                                <div className='topics__item topics__item--plain'>Explainer</div>
                                            </div>
              </div>

            </div>
          </div>
          
        </section> */}



      </div>
    </div>
  );
}

export default KnowledgeHubPost1